<template>
    <div id="doQuestionView">
        <a-row :gutter="[24,24]">
            <a-col :md="12" :xs="24">
                <a-tabs default-active-key="question">
                    <a-tab-pane key="question" title="题目">
                        <a-card v-if="question" :title="question.title">
                            <h2>题目内容</h2>
                            <MdViewer :value="question.content || ''"></MdViewer>
                            <a-divider/>
                            <a-descriptions
                                    title="判题条件"
                                    :column="{ xs: 1, md: 2, lg: 3 }"
                            >
                                <a-descriptions-item label="时间限制">
                                    {{ question.judgeConfig.timeLimit ?? 0 }}
                                </a-descriptions-item>
                                <a-descriptions-item label="内存限制">
                                    {{ question.judgeConfig.memoryLimit ?? 0 }}
                                </a-descriptions-item>
                                <a-descriptions-item label="堆栈限制">
                                    {{ question.judgeConfig.stackLimit ?? 0 }}
                                </a-descriptions-item>
                                {{question.judgeCase}}
                            </a-descriptions>

                            <template #extra>
                                <a-space wrap>
                                    <a-tag
                                            v-for="(tag, index) of question.tags"
                                            :key="index"
                                            color="green"
                                    >{{ tag }}
                                    </a-tag>
                                </a-space>
                            </template>

                        </a-card>
                    </a-tab-pane>

                    <!--评论-->

                    <a-tab-pane key="comment" title="评论">
<!--                        <a-card title="评论区">-->
                            <a-list :data="dataList">
                                <template #item="{ item }">
                                    <CommentCard :tbCommentVO="item"></CommentCard>
                                </template>
                            </a-list>
                            <a-divider></a-divider>
                            <div style="height: 28px"></div>
                            <h3>写评论</h3>
                            <a-textarea v-model="content" auto-size style="height: 68px"></a-textarea>
                            <div style="height: 18px"></div>
                            <a-button type="primary" @click="doComment">发表</a-button>
<!--                        </a-card>-->
                    </a-tab-pane>

                    <a-tab-pane key="answer" title="答案">
                        <a-empty>
                            <template #image>
                                <icon-exclamation-circle-fill />
                            </template>
                            暂无答案
                        </a-empty>
                    </a-tab-pane>
                </a-tabs>
            </a-col>
            <a-col :md="12" :xs="24">

                <a-form :model="form" layout="inline">
                    <a-form-item
                            field="language"
                            label="编程语言"
                            style="min-width: 240px"
                    >
                        <a-select
                                v-model="form.language"
                                :style="{ width: '320px' }"
                                placeholder="选择编程语言"
                        >
                            <a-option>java</a-option>
                            <a-option>python</a-option>
                            <a-option>c</a-option>
<!--                            <a-option>cpp</a-option>-->
<!--                            <a-option>go</a-option>-->
<!--                            <a-option>html</a-option>-->
                        </a-select>
                    </a-form-item>
                </a-form>

                <CodeEdit :value="form.code as string" :language="form.language" :handle-change="doChange"></CodeEdit>
                <a-divider size="0"/>
                <a-space size="large">
                    <a-button type="primary" style="min-width: 200px" @click="doSubmit" :loading="load">
                        提交代码
                    </a-button>

                    <a-button type="primary" status="success" style="min-width: 200px" @click="doAISubmit" :loading="load">
                        AI判题
                    </a-button>
                </a-space>

            </a-col>
        </a-row>
    </div>
</template>

<script setup lang="ts">
import {
    addQuestionSubmitByAiUsingPost,
    addQuestionSubmitUsingPost,
    getQuestionVoByIdUsingGet
} from "@/api/questionController";
    import {ref, withDefaults, defineProps, onMounted, reactive} from 'vue'
    import message from "@arco-design/web-vue/es/message";
    import CodeEdit from "@/components/CodeEdit.vue"
    import MdViewer from "@/components/MdViewer.vue"
    import myAxios from "@/request";
    import {editor} from "monaco-editor";
    import addCommand = editor.addCommand;
    import {addTbCommentUsingPost, listTbCommentVoByPageUsingPost} from "@/api/tbCommentController";
    import TbCommentVO = API.TbCommentVO;
    import CommentCard from '@/components/CommentCard'
    import {useStore} from "vuex";
    import { IconExclamationCircleFill } from '@arco-design/web-vue/es/icon';

    const form = ref<API.QuestionSubmitAddRequest>({
        code: "",
        language: "java",
    })

    interface Props {
        id: string,
    }

    const props = withDefaults(defineProps<Props>(), {
        id: () => ""
    })

    const question = ref<API.QuestionVO>();

    const loadData = async () => {
        // const response = await myAxios.get("/api/question/get/code_recode");
        // if(response.data.code === 0){
        //     form.value.code = response.data.data;
        // }
        const res = await getQuestionVoByIdUsingGet({id: props.id as any})
        if (res.data.code === 0) {
            question.value = res.data.data;
        } else {
            message.error("获取题目失败" + res.data.message)
        }
    }

    // 获取评论
    const dataList = ref<TbCommentVO[]>([]);
    const loadComment = async () =>{
        const res = await listTbCommentVoByPageUsingPost({
            questionId: props.id as any
        });
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
        }else{
            message.error("获取评论失败")
        }
    }


    /**
     * 页面加载时，请求数据
     */
    onMounted(() => {
        loadData();
        loadComment();
    });

    const doChange = (value: string) => {
        form.value.code = value
    }

    const status = ref();
    //提交后等待
    const load = ref(false)
    const doSubmit = async () => {
        if (!props.id) {
            return
        }
        load.value = true
        const res = await addQuestionSubmitUsingPost({
            code: form.value.code,
            language: form.value.language,
            questionId: props.id as any,
        });
        if (res.data.code === 0) {
            // message.success("提交成功");
            status.value = res.data.data;
            if (status.value === 'Accepted') {
                alert("答案正确✔")
            } else {
                alert("回答错误❌")
            }
        } else {
            message.error("提交失败");
        }
        load.value = false
    }

    //写评论
    const store = useStore();
    const content = ref('');

    //发表评论
    const doComment = async () =>{
        const res = await addTbCommentUsingPost({
            questionId: props.id as any,
            userId: store.state.user.loginUser.id,
            content: content.value
        })
        if(res.data.code === 0){
            loadComment()
            message.success("发表成功")
            content.value = ''
        }else{
            message.error("发表失败")
        }
    }

    const doAISubmit = async () =>{
      if (!props.id) {
          return
      }
      message.success("AI判题中，请稍等。。。。")
      load.value = true
      const res = await addQuestionSubmitByAiUsingPost({
          code:form.value.code,
          language:form.value.language,
          questionId:props.id as any,
      });
      if(res.data.code === 0){
          status.value = res.data.data;
          if (status.value === 'Accepted') {
              alert("答案正确✔")
          } else {
              alert("回答错误❌")
          }
      }else{
          message.error("AI判题失败" + res.data.message)
      }
      load.value = false
    }


</script>

<style>
    /*#doQuestionView {*/
    /*    max-width: 1400px;*/
    /*    margin: 0 auto;*/
    /*}*/

    #viewQuestionView .arco-space-horizontal .arco-space-item {
        margin-bottom: 0 !important;
    }

</style>