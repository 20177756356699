<template>
  <div id="userLoginPage">
    <h2>用户登录</h2>
    <a-form :model="form"
            label-align="left"
            auto-label-width
            :style="{ width: '600px',margin:'0 auto',marginTop:'68px'}"
            @submit="handleSubmit">
      <a-form-item field="userAccount" label="账号">
        <a-input
                v-model="form.userAccount"
                placeholder="请输入账号"
        />
      </a-form-item>

      <a-form-item field="userPassword" label="密码">
        <a-input-password v-model="form.userPassword" placeholder="请输入密码" />
      </a-form-item>

      <a-form-item field="checkPassword" label="确认密码">
        <a-input-password v-model="form.checkPassword" placeholder="请输入确认密码" />
      </a-form-item>

      <a-form-item>
        <div
                style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px">
            注册
          </a-button>
          <a-link href="/user/login">老用户登录</a-link>
        </div>
      </a-form-item>
    </a-form>
  </div>

</template>

<script setup lang="ts">
  import { reactive } from 'vue';
  import message from "@arco-design/web-vue/es/message"
  import {useRouter} from "vue-router";
  import {userRegisterUsingPost} from "@/api/userController";

  // const loginUserStore = useLoginUserStore();
  const router = useRouter();
  //
  const form = reactive({
    userAccount: '',
    userPassword: '',
    checkPassword: ''
  });

  const handleSubmit = async () =>{
      const res = await userRegisterUsingPost(form);
      if(res.data.code === 0){
          message.success("注册成功")
          router.push({
              path:'/user/login',
              replace:true
          })
      }else{
          message.error("注册失败" + res.data.message);
      }
  }
</script>