<template>
    <div id="userLayout">
        <a-layout style="min-height: 100vh">
            <a-layout-header class="header">
                <a-space>
                    <img src="../assets/zoj.logo.png" class="logo" />
                    <div>ZOJ</div>
                </a-space>
            </a-layout-header>
            <a-layout-content class="content">
                <router-view />
            </a-layout-content>
            <a-layout-footer class="footer">
                    作者：Hines
            </a-layout-footer>
        </a-layout>
    </div>
</template>

<style scoped>
    #userLayout {
        text-align: center;
        background: url("https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png")
        0% 0% / 100% 100%;
    }

    #userLayout .logo {
        width: 48px;
        height: 48px;
        border-radius: 20%;
    }

    #userLayout .header {
        margin-top: 16px;
        font-size: 21px;
    }

    #userLayout .content {
        margin-bottom: 16px;
        padding: 20px;
    }

    #userLayout .footer {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 16px;
        text-align: center;
    }
</style>