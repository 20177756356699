import ACCESS_ENUM from "@/access/accsessEnum";

/**
 * 检查权限
 * @param loginUser:当前登录用户
 * @param needAccess:所需得权限
 */
const checkAccess = (
    //API.LoginUserVO
  loginUser:any ,
  needAccess = ACCESS_ENUM.NOT_LOGIN
) => {
  //获取当前登录用户得权限，若当前登录用户为空则权限为未登录
  const loginUserAccess = loginUser?.userRole ?? ACCESS_ENUM.NOT_LOGIN;
  console.log("当前权限"+loginUserAccess)
  //若当前访问得页面所需要得权限是未登录就直接返回
  if (needAccess === ACCESS_ENUM.NOT_LOGIN) {
    return true;
  }
  //当前访问得页面所需要得权限是已登录用户才能访问
  if (needAccess === ACCESS_ENUM.USER) {
    if (loginUserAccess === ACCESS_ENUM.NOT_LOGIN) {
      return false;
    }
    return true;
  }
  //当前访问得页面所需要得权限是管理员才能访问
  if (needAccess === ACCESS_ENUM.ADMIN) {
    if (loginUserAccess !== ACCESS_ENUM.ADMIN) {
      return false;
    }
    return true;
  }
};

export default checkAccess;
