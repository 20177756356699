<template>
    <div class="post-confirm_box">
        <a-comment
                author="Socrates"
                content="Comment body content."
                datetime="1 hour"
        >
            <template #actions>
      <span class="action" key="heart" @click="onLikeChange">
        <span v-if="like">
          <IconHeartFill :style="{ color: '#f53f3f' }" />
        </span>

      </span>
                <span class="action" key="star" @click="onStarChange">
        <span v-if="star">
          <IconStarFill style="{ color: '#ffb400' }" />
        </span>
        <span v-else>
          <IconStar />
        </span>
        {{ 3 + (star ? 1 : 0) }}
      </span>
                <span class="action" key="reply">
        <IconMessage /> Reply
      </span>
            </template>
            <template #avatar>
                <a-avatar>
                    <img
                            alt="avatar"
                            src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp"
                    />
                </a-avatar>
            </template>
        </a-comment>
    </div>

</template>

<script setup>

import {myAvatar} from "@/constant/photo";
import {ref} from "vue";

const message = ref('测试测试')
</script>

<!--<style scoped lang="scss" src="./styles.scss">-->

<!--</style>-->
<style scoped>
.post-confirm_box {
  padding: 0 10px 10px;

  .contact-info {
    display: flex;
    align-items: center;

    .session-avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 10px;
    }
  }

  .msg-body {
    display: -webkit-box;
    width: 100%;
    height: 70px;
    padding: 5px 10px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    border: 2px black solid;
    background-color: var(--background-dark);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

</style>