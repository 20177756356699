<template>
    <a-card hoverable :style="{ width: '360px', marginBottom: '20px' }">
        <div :style="{display: 'flex',alignItems: 'center',justifyContent: 'space-between',}">
          <span :style="{ display: 'flex', alignItems: 'center', color: '#1D2129' }">
            <a-avatar :style="{ marginRight: '8px', backgroundColor: '#165DFF' }" :size="28">
              <img :src="friendVO.userAvatar ?? myAvatar">
            </a-avatar>
            <a-typography-text>{{friendVO.userName ?? '无名'}}</a-typography-text>
          </span>
          申请备注：{{friendVO.remark ?? '暂无'}}
            <button type="primary" @click="doAgreen(friendVO.id)">同意</button>
        </div>
    </a-card>
</template>

<script setup lang="ts">
    import {myAvatar} from '@/constant/photo';
    import {agreeApplyUsingPost} from "@/api/friendsController";
    import message from "@arco-design/web-vue/es/message";

    interface Props{
        friendVO:API.FriendsVO
    }

    const props = withDefaults(defineProps<Props>(),{
        friendVO:() =>{
            return {};
        }
    })

    const doAgreen = async (id:number) =>{
        const res = await agreeApplyUsingPost({fromId:id});
        if(res.data.code === 0){
            message.success("同意成功")
        }else{
            message.error("同意失败")
        }
    }
</script>

<style scoped>

</style>