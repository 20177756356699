<template>
    <icon-user-add size="30px" style="margin-bottom: 24px;cursor: pointer" @click="handleMessage"/>
    <icon-message size="30px" style="margin-bottom: 24px;margin-left: 1100px;cursor: pointer" @click="toMyMessage"/>

    <a-modal v-model:visible="addMessage" hide-cancel="true" >
        <template #title>
            好友通知
        </template>
<!--        <div v-if="FriendsVOList === []">-->
<!--            {{暂无}}-->
<!--        </div>-->
        <div v-for="friend in FriendsVOList">
            <FriendCard :friendVO="friend" ></FriendCard>
        </div>
    </a-modal>

    <a-card title="交友区（来寻找小伙伴一起共同刷题吧！！！）">
        <template v-for="userVO in dataList">
<!--            <PostCard :PostVO="postVO"></PostCard>-->
            <UserCard :userVO="userVO" @click="handleClick(userVO.id)"></UserCard>
            <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel" width="500px">
                <template #title>
                    加好友
                </template>
                <a-avatar style="margin-left: 200px;" :size="64">
                    <img :src="userVO.userAvatar ?? myAvatar">
                </a-avatar>
                <div style="text-align: center;margin-top: 18px;font-weight: bold">{{nowUser.userName ?? '无名'}}</div>
                <div style="text-align: center;margin-top: 18px;">{{nowUser.userProfile ?? '这个人很懒，未写任何简介....'}}</div>
                <a-textarea v-if="isMyFriend === false" v-model="remark" placeholder="请填写申请备注....."></a-textarea>
                <button v-if="isMyFriend === false"  type="primary" style="margin-left: 200px;margin-top: 18px;" @click="doApplyFriend(nowUser.id)">加好友</button>
                <a-tag v-else color="green" style="margin-left: 200px;margin-top: 18px">已是好友</a-tag>
            </a-modal>
        </template>
    </a-card>


</template>

<script setup lang="ts">
    import PostCard from '@/components/PostCard'
    import {ref,onMounted} from 'vue'
    import {addPostUsingPost, listPostByPageUsingPost, listPostVoByPageUsingPost} from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";
    import UserCard from '@/components/UserCard'
    import {getUserVoByIdUsingGet, isMyFriendUsingPost, listUserVoByPageUsingPost} from "@/api/userController";
    import {myAvatar} from '@/constant/photo'
    import {agreeApplyUsingPost, applyAddUserUsingPost, showFriendsApplyListUsingGet} from "@/api/friendsController";
    import FriendsVO = API.FriendsVO;
    import FriendCard from '@/components/FriendCard.vue'
    import {useRouter} from "vue-router";

    const router = useRouter();

    const dataList = ref<API.UserVO[]>([])
    const loadData = async () =>{
        const res = await listUserVoByPageUsingPost({});
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
        }else{
            message.error("获取失败");
        }
    }

    const toMyMessage = () =>{
        router.push({
            path:"/message"
        })
    }

    onMounted(() =>{
        loadData();
    })

    //帖子内容
    const content = ref('');
    //梯子标题
    const title = ref('');

    //同步输入的内容
    const doWriteContent = (value:string) =>{
        content.value = value
    }

    const visible = ref(false);

    const isMyFriend = ref<boolean>(false);
    const nowUser = ref<API.UserVO[]>([]);
    const handleClick = async (id:number) => {
        visible.value = true;
        const response = await getUserVoByIdUsingGet({id:id});
        if(response.data.code === 0){
            nowUser.value = response.data?.data as [];
        }else{
            message.error("获取失败" + response.data.message)
        }
        const res = await isMyFriendUsingPost({fromId:id});
        if(res.data.code === 0){
            isMyFriend.value = res.data.data as boolean;
        }else{
            message.error("判断失败"+res.data.message);
        }

    };
    const handleOk = async () => {
        const res =await addPostUsingPost({
            title:title.value,
            content:content.value
        })
        if(res.data.code === 0){
            message.success("创建成功！")
            loadData()
        }else{
            message.error("创建失败！")
        }
    };
    const handleCancel = () => {
        visible.value = false;
    }

    const store = useStore();
    const remark = ref('')
    const doApplyFriend = async (id:number) =>{
        const res =await applyAddUserUsingPost({
            receiveId: id,
            remark:remark.value
        })
        if(res.data.code === 0){
            message.success("已发送申请")
        }else{
            message.error("发送失败" + res.data.message)
        }
    }

    //获取好友申请列表接口
    const FriendsVOList = ref<FriendsVO[]>([])
    const loadApplyList = async () =>{
        const res = await showFriendsApplyListUsingGet();
        if(res.data.code === 0){
            FriendsVOList.value = res.data.data || [];
        }else{
            message.error("获取好友申请失败" + res.data.message)
        }
    }

    const addMessage =ref(false);
    const handleMessage = () =>{
        loadApplyList();
        addMessage.value = true;
    }
</script>

<style scoped>

</style>