<template>
  <div id="userLoginPage">
    <h2>用户登录</h2>
    <a-form :model="form"
            label-align="left"
            auto-label-width
            :style="{ width: '600px',margin:'0 auto',marginTop:'68px'}"
            @submit="handleSubmit">
      <a-form-item field="userAccount" label="账号">
        <a-input
                v-model="form.userAccount"
                placeholder="请输入账号"
        />
      </a-form-item>

      <a-form-item field="userPassword" label="密码">
        <a-input-password v-model="form.userPassword" placeholder="请输入密码" />
      </a-form-item>

      <a-form-item>
        <div
                style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px">
            登录
          </a-button>
          <a-link href="/user/register">新用户注册</a-link>
        </div>
      </a-form-item>
    </a-form>
  </div>

</template>

<script setup lang="ts">
  import { reactive } from 'vue';
  import message from "@arco-design/web-vue/es/message"
  import {useRouter} from "vue-router";
  import {useStore} from 'vuex'
  import {userLoginUsingPost} from "@/api/userController";

  const router = useRouter();
  const store = useStore();

  const form = reactive({
    userAccount: '',
    userPassword: '',
  });

  /**
   * 登录请求
   */
  const handleSubmit = async () => {
        const res = await userLoginUsingPost(form)
        if(res.data.code === 0){
          await store.dispatch("user/getLoginUser")
          message.success("登录成功")
          router.push({
            path:"/",
            replace:true
          })
        }else{
          message.error("登录失败" + res.data.message);
        }
      };
</script>