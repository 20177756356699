// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addTeam POST /api/team/add */
export async function addTeamUsingPost(body: API.TeamAddRequest, options?: { [key: string]: any }) {
  return request<API.BaseResponseLong_>('/api/team/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** LeaderQuitTeam POST /api/team/delete */
export async function leaderQuitTeamUsingPost(
  body: API.TeamQuitRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/team/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getTeamById GET /api/team/get */
export async function getTeamByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTeamByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseTeam_>('/api/team/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** getTeamRank GET /api/team/get_rank */
export async function getTeamRankUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTeamRankUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTeamRankVO_>('/api/team/get_rank', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** joinTeam POST /api/team/join */
export async function joinTeamUsingPost(
  body: API.TeamJoinRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/team/join', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getMyCreateTeam GET /api/team/list/my/create */
export async function getMyCreateTeamUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMyCreateTeamUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListTeamUserVO_>('/api/team/list/my/create', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** getMyJoinTeam GET /api/team/list/my/join */
export async function getMyJoinTeamUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMyJoinTeamUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListTeamUserVO_>('/api/team/list/my/join', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** getTeamListPage GET /api/team/list/page */
export async function getTeamListPageUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTeamListPageUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTeamUserVO_>('/api/team/list/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** quitTeam POST /api/team/quit */
export async function quitTeamUsingPost(
  body: API.TeamQuitRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/team/quit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** update POST /api/team/update */
export async function updateUsingPost(
  body: API.TeamUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/team/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
