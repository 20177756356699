<template>
    <a-button @click="handleClick" type="primary" style="margin-bottom: 24px">
<!--        <template #icon>-->
<!--            <icon-plus />-->
<!--        </template>-->
        <template #icon>
            <icon-edit />
        </template>
        发帖
    </a-button>

    <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel" width="auto">
        标题：<a-input v-model="title" style="width: 320px;margin-bottom: 24px" placeholder="输入帖子标题" allow-clear />
        <template #title>
            创建帖子
        </template>
        <MdEditor :value="content" :handle-change="doWriteContent"></MdEditor>
    </a-modal>

    <a-card title="帖子区">

        <template v-for="postVO in dataList">
            <PostCard :PostVO="postVO"></PostCard>
        </template>
    </a-card>
</template>

<script setup lang="ts">
    import PostCard from '@/components/PostCard'
    import {ref,onMounted} from 'vue'
    import {addPostUsingPost, listPostByPageUsingPost, listPostVoByPageUsingPost} from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";

    const dataList = ref<PostVO[]>([])
    const loadData = async () =>{
        const res = await listPostVoByPageUsingPost({});
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
        }else{
            message.error("获取失败");
        }
    }

    onMounted(() =>{
        loadData();
    })

    //帖子内容
    const content = ref('');
    //梯子标题
    const title = ref('');

    //同步输入的内容
    const doWriteContent = (value:string) =>{
        content.value = value
    }

    const visible = ref(false);

    const handleClick = () => {
        visible.value = true;
    };
    const handleOk = async () => {
        const res =await addPostUsingPost({
            title:title.value,
            content:content.value
        })
        if(res.data.code === 0){
            message.success("创建成功！")
            loadData()
        }else{
            message.error("创建失败！")
        }
    };
    const handleCancel = () => {
        visible.value = false;
    }

</script>

<style scoped>

</style>