// initial state
import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accsessEnum";
import {getLoginUserUsingGet} from "@/api/userController";

/**
 * 通过vuex存储当前登录用户的信息
 */
export default {
    namespaced: true,
    state: () => ({
        loginUser: {
            userName: "未登录",
            // userRole:ACCESS_ENUM.NOT_LOGIN
        },
    }),
    actions: {
        async getLoginUser({ commit, state }, payload) {
            // commit("updateUser", payload);
            const res = await getLoginUserUsingGet();
            console.log("当前登录aaaa："+res.data.data)
            if(res.data.code === 0){
                commit("updateUser",res.data.data)
            }
            else{
                commit("updateUser",{
                    ...state.loginUser,
                    userRole:ACCESS_ENUM.NOT_LOGIN
                })
            }
        },
    },
    mutations: {
        updateUser(state, payload) {
            state.loginUser = payload;
        },
    },
} as StoreOptions<any>;