<template>
  <div id="managerQuestion">
      <a-input-search v-model="searchText" :style="{width:'320px'}" placeholder="请输入搜索关键词" search-button @search="doSubmit"/>

    <a-divider size="0"></a-divider>

    <a-table :columns="columns" :data="dataList"
             :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
             @page-change="onPageChange"
    >
      <template #createTime="{ record }">
        {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template #tags="{ record }">
<!--        <a-tag v-if="record.tags === []" color="gray" checkable>{{暂无}}</a-tag>-->
        <a-tag v-for="(tag, index) of record.tags" :key="index" color="green">{{ tag }}</a-tag>
      </template>
      <template #acceptedRate="{ record }" >
          <a-tag color="orange">{{ `${(record.submitNum ? Math.floor(record.acceptedNum / record.submitNum * 100) : "0")}% (${record.acceptedNum}/${record.submitNum})` }}</a-tag>
      </template>

      <template #optional="{ record }">
        <a-space>
          <a-button type="primary"  @click="doQuestion(record.id)">做题</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
  import {ref,onMounted,watchEffect} from "vue";
  import {
      deleteQuestionUsingPost, listEsQuestionUsingPost,
      listQuestionByPageUsingPost,
      listQuestionVoByPageUsingPost
  } from "@/api/questionController";
  import message from "@arco-design/web-vue/es/message"
  import { dayjs } from "@arco-design/web-vue/es/_utils/date";
  import {useRouter} from "vue-router";
  import myAxios from "@/request";
  import {FileItem} from "@arco-design/web-vue";

  const router = useRouter();
  const show = ref(true)

  const columns = [
    {
      title: "id",
      dataIndex: "id",
    },
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "标签",
      dataIndex: "tags",
      slotName: "tags",
    },
    {
      title: "通过率",
      dataIndex: "acceptedRate",
      slotName: "acceptedRate"
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      slotName: "createTime",
    },
    {
      slotName: "optional",
    },
  ];

  //获取表格数据的请求参数
  // 初始化搜索条件（不应该被修改）
  const initSearchParam = ref<API.QuestionQueryRequest>({
    title:"",
    tags:[],
    pageSize:10,
    current:1
  })
  const searchParam = ref({
    ...initSearchParam.value
  })
  //定义总条数
  const total = ref<number>(0)
  const dataList = ref<API.QuestionVO[]>([]);
  const loadData = async () =>{
    const res = await listQuestionVoByPageUsingPost(searchParam.value);
    if(res.data.code === 0){
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    }else{
      message.error("获取信息失败"+res.data.message);
    }
  }

  //改变页数
  //改页数事件
  const onPageChange = (page:number) =>{
    searchParam.value = {
      ...searchParam.value,
      current:page
    }
  }

  //跳转到做题页面
  const doQuestion = (id:number) =>{
    router.push({
      path:`/do/question/${id}`
    })
  }

  /**
   * 监听 searchParams 变量，改变时触发数据的重新加载
   */
  watchEffect(() =>{
    loadData()
  })

  /**
   * 执行搜索按钮
   */
  const searchText = ref('');
  const doSubmit = async () =>{
    const res = await listEsQuestionUsingPost({
        searchText:searchText.value
    });
    if(res.data.code === 0){
        dataList.value = res.data.data?.records || [];
        total.value = res.data.data?.total || 0;
    }else{
        message.error("搜索失败!")
    }
    // //重新将当前页面置为1
    // searchParam.value = {
    //   ...searchParam.value,
    //   current:1
    // }
    // loadData();

  }

  const formData = new FormData();

  const onChange = async (_: any, currentFile: FileItem) =>{
      formData.append("file",currentFile.file as Blob)
      const res = await myAxios.post("/api/file/upload",formData);
      if(res.data.code === 0){
          message.success("上传成功")
      }else{
          message.error("上传失败")
      }
  }

  const doDownload = async () =>{
      const res = await myAxios.get("/api/file/download")
      if(res.data.code === 0){
          message.success("导入成功")
      }else{
          message.error("导入失败")
      }
  }

</script>

<style scoped>
/*@import '@/css/chat.css';*/

body.ifr:not(.phone) .pnl-body .pnl-message .msg-ball .msg-anchor, body.ifr:not(.phone) .pnl-body .pnl-message .msg-ball a {
    color: #4270f6
}

  #managerQuestion {
    max-width: 1280px;
    margin:0 auto;
  }
</style>
