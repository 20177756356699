<template>
    <div id="addQuestionView">
        <a-space size="large">
            <h2>创建题目</h2>
            <a-button type="primary" @click="handleClick" :loading="submitting">AI生成题目</a-button>
        </a-space>

        <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel">
            <template #title>
                AI生成题目
            </template>

            <a-form :model="aiRequest"  @submit="handleSubmit">
                <a-form-item field="name" label="题目标题">
                    <a-input v-model="aiRequest.title" placeholder="请输入题目标题"/>
                </a-form-item>
                <a-form-item field="step" label="输入用例个数">
                    <a-input-number :style="{ width: '100px' }" v-model="aiRequest.inputNum" />
                </a-form-item>
                <a-form-item label="输出用例个数" field="step">
                    <a-input-number :style="{ width: '100px' }" v-model="aiRequest.outputNum" />
                </a-form-item >
            </a-form>
        </a-modal>

        <a-form :model="form" label-align="left">
            <a-form-item field="title" label="标题">
                <a-input v-model="form.title" placeholder="请输入标题" />
            </a-form-item>
            <a-form-item field="tags" label="标签">
                <a-input-tag v-model="form.tags" placeholder="请选择标签" allow-clear />
            </a-form-item>
            <a-form-item field="content" label="题目内容">
                <MdEditor :value="form.content" :handle-change="onContentChange" />
            </a-form-item>
<!--            <a-form-item field="answer" label="答案">-->
<!--                <MdEditor :value="form.answer" :handle-change="onAnswerChange" />-->
<!--            </a-form-item>-->
            <a-form-item field="answer" label="答案">
                <CodeEdit :value="form.answer" :language="java" :handle-change="doChange" style="width: 700px"></CodeEdit>
            </a-form-item>
            <a-form-item label="判题配置" :content-flex="false" :merge-props="false">
                <a-space direction="vertical" style="min-width: 480px">
                    <a-form-item field="judgeConfig.timeLimit" label="时间限制">
                        <a-input-number
                                v-model="form.judgeConfig.timeLimit"
                                placeholder="请输入时间限制"
                                mode="button"
                                min="0"
                                size="large"
                        />
                    </a-form-item>
                    <a-form-item field="judgeConfig.memoryLimit" label="内存限制">
                        <a-input-number
                                v-model="form.judgeConfig.memoryLimit"
                                placeholder="请输入内存限制"
                                mode="button"
                                min="0"
                                size="large"
                        />
                    </a-form-item>
                    <a-form-item field="judgeConfig.stackLimit" label="堆栈限制">
                        <a-input-number
                                v-model="form.judgeConfig.stackLimit"
                                placeholder="请输入堆栈限制"
                                mode="button"
                                min="0"
                                size="large"
                        />
                    </a-form-item>
                </a-space>
            </a-form-item>
            <a-form-item
                    label="测试用例配置"
                    :content-flex="false"
                    :merge-props="false"
            >
                <a-form-item
                        v-for="(judgeCaseItem, index) of form.judgeCase"
                        :key="index"
                        no-style
                >
                    <a-space direction="vertical" style="min-width: 640px">
                        <a-form-item
                                :field="`form.judgeCase[${index}].input`"
                                :label="`输入用例-${index}`"
                                :key="index"
                        >
                            <a-input
                                    v-model="judgeCaseItem.input"
                                    placeholder="请输入测试输入用例"
                            />
                        </a-form-item>
                        <a-form-item
                                :field="`form.judgeCase[${index}].output`"
                                :label="`输出用例-${index}`"
                                :key="index"
                        >
                            <a-input
                                    v-model="judgeCaseItem.output"
                                    placeholder="请输入测试输出用例"
                            />
                        </a-form-item>
                        <a-button status="danger" @click="handleDelete(index)">
                            删除
                        </a-button>
                    </a-space>
                </a-form-item>
                <div style="margin-top: 32px">
                    <a-button @click="handleAdd" type="outline" status="success"
                    >新增测试用例
                    </a-button>
                </div>
            </a-form-item>
            <div style="margin-top: 16px" />
            <a-form-item>
                <a-button type="primary" style="min-width: 200px" @click="doSubmit"
                >提交
                </a-button>
            </a-form-item>
        </a-form>
    </div>
<!--    {{form.answer}}-->
</template>

<script setup lang="ts">
    import { onMounted, ref } from "vue";
    import MdEditor from "@/components/MdEditor.vue";
    import message from "@arco-design/web-vue/es/message";
    import { useRoute ,useRouter} from "vue-router";
    import {
        addQuestionByAiUsingPost,
        addQuestionUsingPost,
        getQuestionVoByIdUsingGet,
        updateQuestionUsingPost
    } from "@/api/questionController";
    import JudgeCase = API.JudgeCase;
    import JudgeConfig = API.JudgeConfig;
    import CodeEdit from '@/components/CodeEdit.vue'

    const route = useRoute();
    const router = useRouter();

    // 如果页面地址包含 update，视为更新页面
    const updatePage = route.path.includes("update");

    let form = ref({
        title: "",
        tags: [],
        answer: "",
        content: "",
        judgeConfig: {
            memoryLimit: 1000,
            stackLimit: 1000,
            timeLimit: 1000,
        },
        judgeCase: [
            {
                input: "",
                output: "",
            },
        ],
    });

    const doChange = (value: string) => {
        form.value.answer = value
    }

    // const onAnswerChange = (value: string) => {
    //     form.value.answer = value;
    // };


    /**
     * 根据题目 id 获取老的数据
     */
    const loadData = async () => {
        const id = route.query.id;
        if (!id) {
            return;
        }
        const res = await getQuestionVoByIdUsingGet(
            {id:id as any}
        );
        if (res.data.code === 0) {
            form.value = res.data.data as any;
            console.log("更新：" + form.value)
            // json 转 js 对象
            if (!form.value.judgeCase) {
                form.value.judgeCase = [
                    {
                        input: "",
                        output: "",
                    },
                ];
            } else {
                form.value.judgeCase = JSON.stringify(form.value.judgeCase as any) as any;
            }
            if (!form.value.judgeConfig) {
                form.value.judgeConfig = {
                    memoryLimit: 1000,
                    stackLimit: 1000,
                    timeLimit: 1000,
                };
            } else {
                form.value.judgeConfig = JSON.stringify(form.value.judgeConfig as any) as any;
                // form.value.judgeConfig = form.value.judgeConfig;
            }
            if (!form.value.tags) {
                form.value.tags = [];
            } else {
                form.value.tags = JSON.parse(form.value.tags as any);
            }
        } else {
            message.error("加载失败，" + res.data.message);
        }
    };


    onMounted(() => {
        loadData();
    });

    const doSubmit = async () => {
        console.log(form.value);
        // 区分更新还是创建
        if (updatePage) {
            const res = await updateQuestionUsingPost(
                form.value
            );
            if (res.data.code === 0) {
                message.success("更新成功");
            } else {
                message.error("更新失败，" + res.data.message);
            }
        } else {
            const res = await addQuestionUsingPost(
                form.value
            );
            if (res.data.code === 0) {
                message.success("创建成功");
                router.push("/")
            } else {
                message.error("创建失败，" + res.data.message);
            }
        }
    };

    /**
     * 新增判题用例
     */
    const handleAdd = () => {
        form.value.judgeCase.push({
            input: "",
            output: "",
        });
    };

    /**
     * 删除判题用例
     */
    const handleDelete = (index: number) => {
        form.value.judgeCase.splice(index, 1);
    };

    const onContentChange = (value: string) => {
        form.value.content = value;
    };



    /**
     * ai生成题目
     */
    const questionList = ref<API.QuestionAIVO[]>([])
    //等待显示
    const submitting = ref(false);
    const aiRequest = ref<API.QuestionAIRequest>({
        title:'',
        inputNum:0,
        outputNum:0,
    })
    const visible = ref(false);

    const handleClick = () => {
        visible.value = true;
    };
    const handleOk = async () => {
        message.success("AI生成中请稍后.......")
        submitting.value = true;
        const res = await addQuestionByAiUsingPost({
            title:aiRequest.value.title,
            inputNum:aiRequest.value.inputNum,
            outputNum:aiRequest.value.outputNum
        })
        if(res.data.code === 0){
            message.success("AI生成成功")
            questionList.value = res.data.data as [];
            // 题目回显
            form.value.title = questionList.value[0].title as string;
            form.value.content = questionList.value[0].content as string;
            form.value.answer = questionList.value[0].answer as any;
            form.value.judgeCase = questionList.value[0].judgeCase as [] || [];
            form.value.tags = questionList.value[0].tags as [] || [];
            form.value.judgeConfig.memoryLimit = Number(questionList.value[0].judgeConfig?.memoryLimit);
            form.value.judgeConfig.stackLimit = Number(questionList.value[0].judgeConfig?.stackLimit) as number;
            form.value.judgeConfig.timeLimit = Number(questionList.value[0].judgeConfig?.timeLimit) as number;
        }else{
            message.error("AI生成失败" + res.data.message)
        }
        submitting.value = false;
    };
    const handleCancel = () => {
        visible.value = false;
    }
</script>

<style scoped>
    #addQuestionView {
    }
</style>
