import router from "@/router";
import ACCESS_ENUM from "@/access/accsessEnum";
import checkAccess from "@/access/checkAccess";
import store from '@/store/'
/**
 * 权限检查
 */
router.beforeEach(async (to, from, next) => {
  //获取当前登录用户
  let loginUser = store.state.user.loginUser;

  //如果之前没尝试获取过当前登录的用户信息，才自动登录
  if (!loginUser || !loginUser.userRole) {
    await store.dispatch("user/getLoginUser");
    loginUser = store.state.user.loginUser;
  }
  console.log("用户"+loginUser.userRole)

  //当前页面需要得权限
  const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
  //要跳转得页面必须登录
  if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
    if (
      !loginUser ||
      loginUser.userRole === ACCESS_ENUM.NOT_LOGIN ||
      !loginUser.userRole
    ) {
      //跳转到登录页
      next(`/user/login/redirect=${to.fullPath}`);
      return
    }
    //如果用户已经登录了，判断权限是否足够，如果不足则跳转到无权限页面
    if (!checkAccess(loginUser, needAccess)) {
      next("/noAuth");
      return;
    }
  }
  //放行
  next();
});
