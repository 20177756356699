<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <UserLayout></UserLayout>
    </template>
    <template v-else>
      <BasicLayout></BasicLayout>
    </template>
  </div>
</template>

<script setup lang="ts">
  import BasicLayout from './layout/BasicLayout.vue'
  import UserLayout from './layout/UserLayout'
  import "@/access"
  import {useRoute} from "vue-router";
  const route = useRoute();
</script>

<style>
</style>
