<template>
    <a-space>
        <a-avatar>
            <img :src="user.userAvatar ? user.userAvatar : myAvatar ">
        </a-avatar>
        <a-textarea placeholder="Please enter something" allow-clear style="width: 680px"/>
        <icon-send :size="32" @click="doSend"/>
    </a-space>

</template>

<script setup>
import {useStore} from "vuex";
import {myAvatar} from "@/constant/photo";

const store = useStore();
const user = store.state.user.loginUser

const doSend = () =>{

}
</script>

<style scoped>

</style>