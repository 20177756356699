<template>
  <div id="teamViewPage">
    <a-form :model="searchParam" layout="inline">
      <a-form-item field="name" label="队伍名称" style="min-width: 240px">
        <a-input v-model="searchParam.name" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item field="description" label="队伍描述" style="min-width: 240px">
        <a-input-tag v-model="searchParam.description" placeholder="请输入队伍描述" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSubmit">提交</a-button>
      </a-form-item>
    </a-form>

    <a-divider size="0"></a-divider>
      <div style="height: 28px"></div>

      <a-button @click="handleClick" type="primary" style="margin-right: 1100px">
          <template #icon>
              <icon-plus />
          </template>
          创建
      </a-button>

      <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel">
          <template #title>
              创建队伍
          </template>
          <a-form :model="form"  @submit="handleSubmit">
              <a-form-item field="name" label="队伍名称">
                  <a-input v-model="form.name" placeholder="请输入队伍名称"/>
              </a-form-item>
              <a-form-item field="description" label="队伍描述">
                  <a-input v-model="form.description" placeholder="请输入队伍描述" />
              </a-form-item>
              <a-form-item label="最大人数" field="step">
                  <a-input-number :style="{ width: '100px' }" v-model="form.maxNum" />
              </a-form-item >
              <a-form-item label="过期时间" field="expireTime">
                  <a-date-picker v-model="form.expireTime" style="width: 200px;" />
              </a-form-item>
              <a-form-item label="状态" field="status">
                  <a-radio-group v-model="form.status">
                      <a-radio value="0">公开</a-radio>
                      <a-radio value="1">私有</a-radio>
                      <a-radio value="2">加密</a-radio>
                  </a-radio-group>
              </a-form-item>
          </a-form>
      </a-modal>

      <a-divider size="0"></a-divider>

    <a-table :columns="columns" :data="dataList"
             :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
             @page-change="onPageChange"
    >
      <template #expireTime="{ record }">
        {{ dayjs(record.expireTime).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
        <template #maxNum="{ record }">
        <a-tag color="red">{{record.maxNum}}</a-tag>
      </template>
        <template #joinNum="{ record }">
            <a-tag color="blue">{{record.joinNum}}</a-tag>
      </template>

      <template #optional="{ record }">
        <a-space>
            <a-tag color="green" v-if="record.hasJoin">已加入</a-tag>
            <a-button type="primary"  @click="doJoinTeam(record.id)" v-else>加入</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
  import {ref,onMounted,watchEffect} from "vue";
  import {
    deleteQuestionUsingPost,
    listQuestionByPageUsingPost,
    listQuestionVoByPageUsingPost
  } from "@/api/questionController";
  import message from "@arco-design/web-vue/es/message"
  import { dayjs } from "@arco-design/web-vue/es/_utils/date";
  import {useRouter} from "vue-router";
  import {
      addTeamUsingPost,
      getTeamListPageUsingGet,
      getTeamListUsingGet,
      joinTeamUsingPost
  } from "@/api/teamController";
  import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
  import TeamAddRequest = API.TeamAddRequest;
  import {useStore} from "vuex";
  import TeamUserVO = API.TeamUserVO;

  const router = useRouter();
  const show = ref(true)

  const columns = [
    {
      title: "队伍id",
      dataIndex: "id",
    },
    {
      title: "队伍名称",
      dataIndex: "name",
    },
    {
      title: "队伍描述",
      dataIndex: "description",
    },
    {
      title: "最大人数",
      dataIndex: "maxNum",
      slotName: "maxNum"
    },
      {
      title: "当前人数",
      dataIndex: "joinNum",
      slotName: "joinNum"
    },
    {
      title: "过期时间",
      dataIndex: "expireTime",
      slotName: "expireTime"
    },
    {
      slotName: "optional",
    },
  ];

  //获取表格数据的请求参数
  // 初始化搜索条件（不应该被修改）
  const initSearchParam = ref<API.TeamQueryRequest>({
    name:'',
    description:'',
    pageSize:10,
    current:1
  })
  const searchParam = ref({
    ...initSearchParam.value
  })
  //定义总条数
  const total = ref<number>(0)
  const dataList = ref<API.TeamUserVO[]>([]);

  const loadData = async () =>{
    const res = await getTeamListPageUsingGet(searchParam.value);
    if(res.data.code === 0){
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    }else{
      message.error("获取信息失败"+res.data.message);
    }
  }


  //改变页数
  //改页数事件
  const onPageChange = (page:number) =>{
    searchParam.value = {
      ...searchParam.value,
      current:page
    }
  }

  /**
   * 监听 searchParams 变量，改变时触发数据的重新加载
   */
  watchEffect(() =>{
    loadData();
  })

  /**
   * 执行搜索按钮
   */
  const doSubmit = () =>{
    //重新将当前页面置为1
    searchParam.value = {
      ...searchParam.value,
      current:1
    }
    loadData();
  }



  const form = ref<TeamAddRequest>({
      name:'',
      description:'',
      maxNum: 0,
      expireTime:"",
      status:0
  })
  const visible = ref(false);

  const store = useStore();

  const handleClick = () => {
      visible.value = true;
  };
  const handleOk = async () => {
      const res = await addTeamUsingPost({
          name:form.value.name,
          description:form.value.description,
          expireTime:form.value.expireTime,
          maxNum:form.value.maxNum,
          status:form.value.status,
          userId: store.state.user.loginUser.id
      })
      if(res.data.code === 0){
          message.success("创建队伍成功");
          loadData();
      }else{
          message.error("创建队伍失败" + res.data.message)
      }
  };
  const handleCancel = () => {
      visible.value = false;
  }

  const doJoinTeam = async (id:number) =>{
      const res = await joinTeamUsingPost({teamId:id});
      if(res.data.code === 0){
          message.success("加入成功")
          loadData();
      }else{
          message.error("加入失败")
      }
  }
</script>

<style scoped>
  #teamViewPage {
    max-width: 1280px;
    margin:0 auto;
  }
</style>
