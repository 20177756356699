import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import 'bytemd/dist/index.css'
import store from "./store";
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
// 引入vue-echarts
import ECharts from 'vue-echarts'
// Vue.message('v-chart', ECharts)
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'



createApp(App).use(store).use(router).use(ArcoVue).use(ArcoVueIcon).use(ElementPlus, {locale: zhCn}).mount("#app");
