<template>
    <div id="questionSubmitView">
        <a-form :model="searchParams" layout="inline">
            <a-form-item field="questionId" label="题号" style="min-width: 240px">
                <a-input v-model="searchParams.questionId" placeholder="请输入" />
            </a-form-item>
            <a-form-item field="language" label="编程语言" style="min-width: 240px">
                <a-select
                        v-model="searchParams.language"
                        :style="{ width: '320px' }"
                        placeholder="选择编程语言"
                >
                    <a-option>java</a-option>
                    <a-option>c</a-option>
                    <a-option>python</a-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="doSubmit">搜索</a-button>
            </a-form-item>
        </a-form>
        <a-divider size="0" />
        <a-table
                :ref="tableRef"
                :columns="columns"
                :data="dataList"
                :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
                @page-change="onPageChange"
        >
            <template #judgeInfo="{ record }">
                <a-space>
                    <a-tag v-if="record.judgeInfo.message === 'Accepted'" color="green">{{record.judgeInfo.message }}</a-tag>
                    <a-tag v-if="record.judgeInfo.message != 'Accepted'" color="red">{{record.judgeInfo.message }}</a-tag>
                    <a-tag color="orange">{{"time："+record.judgeInfo.time }}</a-tag>
                    <a-tag color="pink">{{"memory："+record.judgeInfo.memory }}</a-tag>
                </a-space>


            </template>
            <template #createTime="{ record }">
                {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template #userName="{ record }">
                {{record.user.userName}}
            </template>
        </a-table>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, watchEffect } from "vue";
    import message from "@arco-design/web-vue/es/message";
    import { useRouter } from "vue-router";
    import QuestionSubmitQueryRequest = API.QuestionSubmitQueryRequest;
    import Question = API.Question;
    import { dayjs } from "@arco-design/web-vue/es/_utils/date";
    import {listQuestionSubmitVoByPageUsingPost} from "@/api/questionController";

    const tableRef = ref();

    const dataList = ref<API.QuestionSubmitVO[]>([]);
    const total = ref(0);
    const searchParams = ref<QuestionSubmitQueryRequest>({
        questionId: undefined,
        language: '',
        pageSize: 10,
        current: 1,
    });

    const loadData = async () => {
        const res = await listQuestionSubmitVoByPageUsingPost(
            {
                ...searchParams.value,
                sortField: "createTime",
                sortOrder: "descend",
            }
        );
        if (res.data.code === 0) {
            dataList.value = res.data.data?.records || [];
            total.value = res.data.data?.total || 0;
        } else {
            message.error("加载失败，" + res.data.message);
        }
    };

    /**
     * 监听 searchParams 变量，改变时触发页面的重新加载
     */
    watchEffect(() => {
        loadData();
    });

    /**
     * 页面加载时，请求数据
     */
    onMounted(() => {
        loadData();
    });

    const columns = [
        {
            title: "提交号",
            dataIndex: "id",
        },
        {
            title: "编程语言",
            dataIndex: "language",
        },
        {
            title: "用户名",
            slotName: "userName",
            width:120
        },
        {
            title: "判题信息",
            slotName: "judgeInfo",
        },
        {
            title: "判题状态",
            dataIndex: "status",
        },
        {
            title: "题目 id",
            dataIndex: "questionId",
        },
        {
            title: "提交者 id",
            dataIndex: "userId",
        },
        {
            title: "创建时间",
            slotName: "createTime",
        },
    ];

    const onPageChange = (page: number) => {
        searchParams.value = {
            ...searchParams.value,
            current: page,
        };
    };

    const router = useRouter();

    /**
     * 跳转到做题页面
     * @param question
     */
    const toQuestionPage = (question: Question) => {
        router.push({
            path: `/do/question/${question.id}`,
        });
    };

    /**
     * 确认搜索，重新加载数据
     */
    const doSubmit = () => {
        // 这里需要重置搜索页号
        searchParams.value = {
            ...searchParams.value,
            current: 1,
        };
    };
</script>

<style scoped>
    #questionSubmitView {
        max-width: 1280px;
        margin: 0 auto;
    }
</style>
