<template>
    <div id="managerQuestion">
        <a-table :columns="columns" :data="dataList"
                 :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
                 @page-change="onPageChange"
        >
            <template #createTime="{ record }">
                {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template #optional="{ record }">
                <a-space>
                    <a-button status="danger"  @click="doDelete(record)">删除</a-button>
                    <a-button type="primary"  @click="doUpdate(record)">修改</a-button>
                </a-space>
            </template>
        </a-table>
    </div>
</template>

<script setup lang="ts">
    import {ref,onMounted,watchEffect} from "vue";
    import {deleteQuestionUsingPost, listQuestionByPageUsingPost} from "@/api/questionController";
    import message from "@arco-design/web-vue/es/message"
    import { dayjs } from "@arco-design/web-vue/es/_utils/date";
    import {useRouter} from "vue-router";

    const router = useRouter();
    const show = ref(true)

    const columns = [
        {
            title: "id",
            dataIndex: "id",
        },
        {
            title: "标题",
            dataIndex: "title",
        },
        {
            title: "内容",
            dataIndex: "content",
        },
        {
            title: "标签",
            dataIndex: "tags",
        },
        {
            title: "答案",
            dataIndex: "answer",
        },
        {
            title: "提交数",
            dataIndex: "submitNum",
        },
        {
            title: "通过数",
            dataIndex: "acceptedNum",
        },
        {
            title: "判题配置",
            dataIndex: "judgeConfig",
        },
        {
            title: "判题用例",
            dataIndex: "judgeCase",
        },
        {
            title: "用户id",
            dataIndex: "userId",
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            slotName: "createTime",
        },
        {
            title: "操作",
            slotName: "optional",
        },
    ];

    //获取表格数据的请求参数
    // 初始化搜索条件（不应该被修改）
    const initSearchParam = ref<API.UserQueryRequest>({
        pageSize:10,
        current:1
    })
    const searchParam = ref({
        ...initSearchParam.value
    })
    //定义总条数
    const total = ref<number>(0)
    const dataList = ref<API.Question[]>([]);
    const loadData = async () =>{
        const res = await listQuestionByPageUsingPost(searchParam.value);
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
            total.value = res.data.data?.total || 0;
        }else{
            message.error("获取信息失败"+res.data.message);
        }
    }

    //删除方法
    const doDelete = async (record:API.Question) =>{
        const res = await deleteQuestionUsingPost({id:record.id});
        if(res.data.code === 0){
            message.success("删除成功!")
            loadData()
        }else{
            message.error("删除失败!")
        }
    }

    //更新方法
    const doUpdate = (record:API.Question) =>{
        router.push({
            path:"/update/question",
            query:{
                id:record.id
            }
        })
    }

    //改变页数
    //改页数事件
    const onPageChange = (page:number) =>{
        searchParam.value = {
            ...searchParam.value,
            current:page
        }
    }

    /**
     * 监听 searchParams 变量，改变时触发数据的重新加载
     */
    watchEffect(() =>{
        loadData()
    })
</script>

<style scoped>
    #managerQuestion {
    }
</style>
