<template>

    <a-card title="我的好友">
        <template v-if="dataList === []">
            暂无好友<a-empty/>
        </template>
        <template v-for="userVO in dataList">
            <UserCard :userVO="userVO"></UserCard>
        </template>

    </a-card>
</template>

<script setup lang="ts">
    import UserCard from '@/components/UserCard'
    import {ref,onMounted} from 'vue'
    import {
        addPostUsingPost,
        listMyPostVoByPageUsingPost,
        listPostByPageUsingPost,
        listPostVoByPageUsingPost
    } from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";
    import {showMyFriendsUsingGet} from "@/api/userController";

    const dataList = ref<API.UserVO[]>([])
    const loadData = async () =>{
        const res = await showMyFriendsUsingGet({});
        if(res.data.code === 0){
            dataList.value = res.data.data || [];
        }else{
            message.error("获取失败");
        }
    }

    onMounted(() =>{
        loadData();
    })

</script>

<style scoped>

</style>