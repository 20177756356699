// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addPostThumb POST /api/postThumb/add */
export async function addPostThumbUsingPost(
  body: API.PostThumbAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/postThumb/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deletePostThumb POST /api/postThumb/delete */
export async function deletePostThumbUsingPost(
  body: API.PostThumbDeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/postThumb/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** editPostThumb POST /api/postThumb/edit */
export async function editPostThumbUsingPost(
  body: API.PostThumbEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/postThumb/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getPostThumbVOById GET /api/postThumb/get/vo */
export async function getPostThumbVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getPostThumbVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePostThumbVO_>('/api/postThumb/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listPostThumbByPage POST /api/postThumb/list/page */
export async function listPostThumbByPageUsingPost(
  body: API.PostThumbQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePagePostThumb_>('/api/postThumb/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listPostThumbVOByPage POST /api/postThumb/list/page/vo */
export async function listPostThumbVoByPageUsingPost(options?: { [key: string]: any }) {
  return request<API.BaseResponseListLong_>('/api/postThumb/list/page/vo', {
    method: 'POST',
    ...(options || {}),
  });
}

/** listMyPostThumbVOByPage POST /api/postThumb/my/list/page/vo */
export async function listMyPostThumbVoByPageUsingPost(
  body: API.PostThumbQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePagePostThumbVO_>('/api/postThumb/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updatePostThumb POST /api/postThumb/update */
export async function updatePostThumbUsingPost(
  body: API.PostThumbUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/postThumb/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
