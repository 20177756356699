// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** addTbComment POST /api/tbComment/add */
export async function addTbCommentUsingPost(
  body: API.TbCommentAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseLong_>('/api/tbComment/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** deleteTbComment POST /api/tbComment/delete */
export async function deleteTbCommentUsingPost(
  body: API.DeleteRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/tbComment/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** editTbComment POST /api/tbComment/edit */
export async function editTbCommentUsingPost(
  body: API.TbCommentEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/tbComment/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getTbCommentVOById GET /api/tbComment/get/vo */
export async function getTbCommentVoByIdUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTbCommentVOByIdUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseTbCommentVO_>('/api/tbComment/get/vo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** listTbCommentByPage POST /api/tbComment/list/page */
export async function listTbCommentByPageUsingPost(
  body: API.TbCommentQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTbComment_>('/api/tbComment/list/page', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listTbCommentVOByPage POST /api/tbComment/list/page/vo */
export async function listTbCommentVoByPageUsingPost(
  body: API.TbCommentQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTbCommentVO_>('/api/tbComment/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** listMyTbCommentVOByPage POST /api/tbComment/my/list/page/vo */
export async function listMyTbCommentVoByPageUsingPost(
  body: API.TbCommentQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTbCommentVO_>('/api/tbComment/my/list/page/vo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** updateTbComment POST /api/tbComment/update */
export async function updateTbCommentUsingPost(
  body: API.TbCommentUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseBoolean_>('/api/tbComment/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
