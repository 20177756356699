<template>
    <div id="userRankPage">
        <a-form :model="searchParam" layout="inline">
            <a-form-item field="title" label="名称" style="min-width: 240px">
                <a-input v-model="searchParam.title" placeholder="请输入名称" />
            </a-form-item>
            <a-form-item field="tags" label="标签" style="min-width: 240px">
                <a-input-tag v-model="searchParam.tags" placeholder="请输入标签" />
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="doSubmit">提交</a-button>
            </a-form-item>
        </a-form>

        <a-divider size="0"></a-divider>

        <a-table :columns="columns" :data="dataList"
                 :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
                 @page-change="onPageChange"
        >
            <template #acceptNum="{ record }">
                <a-tag color="red">{{ record.acceptNum }}</a-tag>
            </template>
        </a-table>
    </div>
</template>

<script setup lang="ts">
    import {ref,onMounted,watchEffect} from "vue";
    import {
        deleteQuestionUsingPost,
        listQuestionByPageUsingPost,
        listQuestionVoByPageUsingPost
    } from "@/api/questionController";
    import message from "@arco-design/web-vue/es/message"
    import { dayjs } from "@arco-design/web-vue/es/_utils/date";
    import {useRouter} from "vue-router";
    import myAxios from "@/request";
    import {getTeamRankUsingGet} from "@/api/teamController";

    const index = ref(1)

    const router = useRouter();
    const show = ref(true)

    const columns = [
        {
            title: "队伍名称",
            dataIndex: "name",

        },
        {
            title: "队伍描述",
            dataIndex: "description",
        },
        {
            title: "总通过数",
            dataIndex: "acceptNum",
            slotName: "acceptNum"
        },
    ];

    //获取表格数据的请求参数
    // 初始化搜索条件（不应该被修改）
    const initSearchParam = ref<API.TeamQueryRequest>({
        pageSize:10,
        current:1,
        // sortField:"acceptNum",
        // sortOrder:"desc"
    })
    const searchParam = ref({
        ...initSearchParam.value
    })
    //定义总条数
    const total = ref<number>(0)
    const dataList = ref<API.User[]>([]);
    const loadData = async () =>{
        const res = await getTeamRankUsingGet(searchParam.value);
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
        }else{
            message.error("获取信息失败"+res.data.message);
        }
    }

    //改变页数
    //改页数事件
    const onPageChange = (page:number) =>{
        searchParam.value = {
            ...searchParam.value,
            current:page
        }
    }

    //跳转到做题页面
    const doQuestion = (id:number) =>{
        router.push({
            path:`/do/question/${id}`
        })
    }

    /**
     * 监听 searchParams 变量，改变时触发数据的重新加载
     */
    watchEffect(() =>{
        loadData()
    })

    /**
     * 执行搜索按钮
     */
    const doSubmit = () =>{
        //重新将当前页面置为1
        searchParam.value = {
            ...searchParam.value,
            current:1
        }
        loadData();
    }
</script>

<style scoped>
    #userRankPage {
        max-width: 1280px;
        margin:0 auto;
    }
</style>
