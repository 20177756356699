<template>
    <div id="globalHeader">
        <a-row class="grid-demo" align="center" :wrap="false">
            <a-col flex="auto">
                <a-menu mode="horizontal" :selected-keys="selectKey" @menu-item-click="doMenu">
                    <a-menu-item key="0" :style="{ padding: 0, marginRight: '38px' }" disabled>
                        <div class="titleBar">
                            <img class="logo" src="../assets/zoj.logo.png">
                            <div class="title">zoj判题系统</div>
                        </div>
                    </a-menu-item>
                    <a-menu-item v-for="item in visitMenu" :key="item.path">
                        {{item.name}}
                    </a-menu-item>
                </a-menu>
            </a-col>

            <a-col flex="200px" v-if="store.state.user.loginUser.userName == '未登录'"  @click="doLogin">
                <a-button type="primary">登录</a-button>
            </a-col>
            <a-col flex="100px" v-else @click="doUserInfo">
                <a-avatar :style="{ backgroundColor: '#3370ff' }" @click="doUser">
                    {{store.state.user.loginUser.userName ?? '无名'}}
                </a-avatar>
            </a-col>
        </a-row>
    </div>
</template>

<script setup lang="ts">
    import {useRouter} from "vue-router";
    import {ref,computed} from 'vue'
    import { routes } from '@/router/routes';
    import {useStore} from "vuex";
    import checkAccess from "@/access/checkAccess";

    const store = useStore();
    const router = useRouter();

    //通过vuex的store获取获取当前登录用户

    //过滤掉路由中meta.hidenMenu为true，不展示在菜单中
    const visitMenu = computed(() =>{
        return routes.filter((item) =>{
            if(item.meta?.hideMenu){
                return false;
            }
            //根据权限过滤菜单
            console.log("现在的user" + store.state.user.loginUser.userRole)
            if(!checkAccess(store.state.user.loginUser,item.meta?.access as string)){
                return false;
            }
            return true
        })
    })

    //控制菜单高亮显示
    const selectKey = ref(["/"]);
    router.afterEach((to, from, failure) => {
        selectKey.value = [to.path];
    })

    /**
     *点击菜单跳转到对应路由
     * @param key
     */
    const doMenu = (key:string) =>{
        router.replace({
            path: key,
        })
    }

    /**
     * 跳转登录页面
     */
    const doLogin = () =>{
        router.push({
            path:"/user/login"
        })
    }

    const doUserInfo = () =>{
        router.push({
            path:"/my/detail"
        })
    }

</script>

<style scoped>
    #globalHeader {

    }

    #globalHeader .titleBar {
        display: flex;
        align-items: center;
    }

    #globalHeader .titleBar .title {
        margin-left: 16px;
        color: black;
        font-weight: bold;
    }

    #globalHeader .titleBar .logo {
        height: 48px;
        border-radius: 20%;
    }
</style>