<template>
    <div :style="{ display: 'flex' }">
        <a-card id="carddemo" :style="{ width: '1280px'}" :title="PostVO.title" hoverable bordered>
            <template #extra>

                {{PostVO.user.userName}}
                <a-avatar :size="24" >
                    <img :src="PostVO.user.userAvatar ?? myAvatar">

                </a-avatar>
            </template>

            <MdViewer :value="PostVO.content || ''"></MdViewer>
            <icon-thumb-up v-if="!PostVO.hasThumb" size="20" style="cursor: pointer" @click="doThumb(PostVO.id)"/>
            <icon-thumb-up-fill v-else size="20" style="cursor: pointer" @click="doDeleteThumb"/>

        </a-card>
    </div>
</template>

<script setup lang="ts">
    import { dayjs } from "@arco-design/web-vue/es/_utils/date";
    import {myAvatar} from "@/constant/photo";
    import MdViewer from "@/components/MdViewer.vue"
    import { Icon } from '@arco-design/web-vue';
    import {ref} from 'vue'
    import {addPostThumbUsingPost, deletePostThumbUsingPost} from "@/api/postThumbController";
    import {useStore} from "vuex";
    import message from "@arco-design/web-vue/es/message";

    const isThumb = ref<boolean>(false);


    interface Props{
        PostVO:API.PostVO
    }
    const props = withDefaults(defineProps<Props>(),{
        PostVO:() =>{
            return {};
        }
    })

    const store = useStore();

    const form = ref<any>({
        postId:props.PostVO.id,
        userId:store.state.user.loginUser.id
    });

    const doThumb = async (id:number) =>{
        const res = await addPostThumbUsingPost(form.value);
        if(res.data.code === 0){
            window.location.reload()
        }else{
            message.error("点赞错误" + res.data.message);
        }
    }

    const doDeleteThumb = async () =>{
        const res = await deletePostThumbUsingPost(form.value);
        if(res.data.code === 0){
            window.location.reload()
        }else{
            message.error("点赞错误" + res.data.message);
        }
    }
</script>

<style scoped>
    .card-demo {
        width: 360px;
        margin-left: 24px;
        transition-property: all;

    }
    #carddemo{
        margin-bottom: 24px;

    }
    .card-demo:hover {
        transform: translateY(-4px);
    }
    /*#avatar{*/
    /*    size: 9px;*/
    /*}*/
</style>